export const tribeTermsAndConditions = `Payment Policy

In order to successfully register and complete your costume booking, you are required in the first instance to issue to us a non-refundable deposit. All additional pieces/add-ons must be paid for in full at the time of registration. Please note that this deposit is required regardless of the price of the costume. You will be required to pay any balance on the selected costume by 31 st December 2023.

Minimum Deposit: TT $2200/US $352 (male and female 'A') or TT $3000/US $480 (female 'X') Large or X-Large Backpacks will be required to pay an additional TT$2,000 (US$320) All Add-ons must be paid for in full at the time of registration.

Costumes Packages must be paid in full prior to collection. Payments could be made via the following options:

1. At the Mas Camp (Cash & Linx) and online via Credit Card (Visa & MasterCard).
2. Cheques will not be accepted as a form of payment.

PLEASE NOTE:

YOUR REGISTRATION WILL ONLY BE COMPLETED AND VALID WHEN THE ENTIRE DEPOSIT IS MADE.

COSTUMES MUST BE PAID FOR IN FULL BY 31 ST DECEMBER 2023

Disclaimers

1. Masqueraders who fail to issue the full deposit by the due date will forfeit any and all monies initially issued as part-payment of the deposit. No exceptions allowed!
2. Costumes remain the property of the band until paid for in FULL and collected.
3. If FULL payment is not paid by the stipulated deadline date, all monies will be forfeited.
4. Please note that ALL credit cards are billed in US$. Important: For multi-currency credit cards, your credit card statement may reflect the billed amount converted at the banks' prevailing rate on that day. Also note that some banks/providers may charge an additional convenience fee.


Refunds/Returns Policy

Please note that carnival costumes are custom-made items therefore once a down-payment is made, it is non-refundable. Additionally, there will be:

• NO exchange or switching of sections.
• NO changing of measurements or sizes
• NO costume Transfers
• NO returns accepted

Delivery Policy

All costumes must be collected at the distribution centre by the owner. You must present a valid form of identification at the time of collection. Alternatively, you may authorize another person to collect on your behalf. The authorized person must present a valid form of identification.

For delivery all costumes must be paid in full to be eligible.

The costume distribution schedule will be emailed to all masqueraders and posted to our social media platforms and website. Different sections will have different distribution dates. The final date of costume distribution is Carnival Thursday, after which all uncollected costumes are no longer available.

Consumer Data Privacy Statement

Information Collection, Use, and Sharing
We are the sole owners of the information collected on this site. We only have access to collect information that you voluntarily give us via email or other direct contact from you. We will not sell this information to anyone.

Your contact information will be used to communicate with you with regards to your costume and Carnival-related business. We may also contact you with special promotions and offers. We will not share your information with any third party outside of our organization.

Security
We take precautions to protect your information. When you submit sensitive information via the website, your information is protected both online and offline.

Wherever we collect sensitive information (such as credit card data), that information is encrypted and transmitted to us in a secure way. You can verify this by looking for “https” at the beginning of the address of the web page.

Customer Service Contact Information

Phone: (868) 235-6800
Email: info@carnivaltribe.com

Experience The Magic Ltd. | 20 Rosalino Street, Woodbrook, Trinidad`;

export const pureTermsAndConditions = `Payment Policy

In order to successfully register and complete your costume booking, you are required in the first instance to issue to us a non-refundable deposit. All additional pieces/add-ons must be paid for in full at the time of registration. Please note that this deposit is required regardless of the price of the costume. You will be required to pay any balance on the selected costume by 31 st December 2023.

Minimum Deposit: TT$1800 (male and backline) or TT $2500 (frontline) Large or X-Large Backpacks will be required to pay an additional TT$2,000 (US$480) All Add-ons must be paid for in full at the time of registration.

All classix sneakers require an initial downpayment of TT $400 ($64 USD)

Costumes packages must be paid in full prior to collection. Payments could be made via the following options:

1. At the Mas Camp (Cash & Linx) and online via Credit Card (Visa & MasterCard).
2. Cheques will not be accepted as a form of payment.

PLEASE NOTE:

YOUR REGISTRATION WILL ONLY BE COMPLETED AND VALID WHEN THE ENTIRE DEPOSIT IS MADE.

COSTUMES MUST BE PAID FOR IN FULL BY 31 ST DECEMBER 2023

Disclaimers

1. Masqueraders who fail to issue the full deposit by the due date will forfeit any and all monies initially issued as part-payment of the deposit. No exceptions allowed!
2. Costumes remain the property of the band until paid in FULL and collected.
3. If FULL payment is not paid by the stipulated deadline date, all monies will be forfeited.
4. Please note that ALL credit cards issued outside of Trinidad & Tobago are billed in US$. Important: For multi-currency credit cards, your credit card statement may reflect the billed amount converted at the banks' prevailing rate on that day. Also note that some banks/providers may charge an additional convenience fee.


Refunds/Returns Policy

Please note that carnival costumes are custom-made items therefore once a down-payment is made, it is non-refundable. Additionally, there will be:

• NO exchange or switching of sections.
• NO changing of measurements or sizes
• NO costume Transfers
• NO returns accepted

Delivery Policy

All costumes must be collected at the distribution centre by the owner. You must present a valid form of identification at the time of collection. Alternatively, you may authorize another person to collect on your behalf. The authorized person must present a valid form of identification.

The costume distribution schedule will be emailed to all masqueraders and posted to our social media platforms and website. Different sections will have different distribution dates. The final date of costume distribution is Carnival Thursday, after which all uncollected costumes are no longer available.

Consumer Data Privacy Statement

Information Collection, Use, and Sharing
We are the sole owners of the information collected on this site. We only have access to collect information that you voluntarily give us via email or other direct contact from you. We will not sell this information to anyone.

Your contact information will be used to communicate with you with regards to your costume and Carnival-related business. We may also contact you with special promotions and offers. We will not share your information with any third party outside of our organization.

Security
We take precautions to protect your information. When you submit sensitive information via the website, your information is protected both online and offline.

Wherever we collect sensitive information (such as credit card data), that information is encrypted and transmitted to us in a secure way. You can verify this by looking for “https” at the beginning of the address of the web page.

Customer Service Contact Information

Phone: (868) 235-6800
Email: info@purecarnivaltt.com

Absolute Passion Ltd. | 20 Rosalino Street, Woodbrook, Trinidad`;

export const hartsTermsAndConditions = `Payment Policy

In order to successfully register and complete your costume booking, you are required in the first instance to issue to us a non-refundable deposit. All additional pieces/add-ons must be paid for in full at the time of registration. Please note that this deposit is required regardless of the price of the costume. You will be required to pay any balance on the selected costume by 31 st December 2023.

Minimum Deposit: TT$2000 (male and female)
All Add-ons must be paid for in full at the time of registration

Costume packages must be paid in full prior to collection. Payments could be made via the following options:

1. At the Mas Camp (Cash & Linx) and online via Credit Card (Visa & MasterCard).
2. Cheques will not be accepted as a form of payment.

PLEASE NOTE:

YOUR REGISTRATION WILL ONLY BE COMPLETED AND VALID WHEN THE ENTIRE DEPOSIT IS MADE.

COSTUMES MUST BE PAID FOR IN FULL BY 31 ST DECEMBER 2023

Disclaimers

1. Masqueraders who fail to issue the full deposit by the due date will forfeit any and all monies initially issued as part-payment of the deposit. No exceptions allowed!
2. Costumes remain the property of the band until paid for in FULL and collected.
3. If FULL payment is not paid by the stipulated deadline date, all monies will be forfeited.
4. Please note that ALL credit cards issued outside of Trinidad & Tobago are billed in US$. Important: For multi-currency credit cards, your credit card statement may reflect the billed amount converted at the banks' prevailing rate on that day. Also note that some banks/providers may charge an additional convenience fee.
5. All

Refunds/Returns Policy

Please note that carnival costumes are custom-made items therefore once a down-payment is made, it is non-refundable. Additionally, there will be:

• NO exchange or switching of sections.
• NO changing of measurements or sizes
• NO costume Transfers
• NO returns accepted

Delivery Policy

All costumes must be collected at the distribution centre by the owner. You must present a valid form of identification at the time of collection. Alternatively, you may authorize another person to collect on your behalf. The authorized person must present a valid form of identification.

The costume distribution schedule will be emailed to all masquerader and posted to our social media platforms and website. Different sections will have different distribution dates. The final date of costume distribution is Carnival Thursday, after which all uncollected costumes are no longer available.

Consumer Data Privacy Statement

Information Collection, Use, and Sharing
We are the sole owners of the information collected on this site. We only have access to collect information that you voluntarily give us via email or other direct contact from you. We will not sell this information to anyone.

Your contact information will be used to communicate with you with regards to your costume and Carnival-related business. We may also contact you with special promotions and offers. We will not share your information with any third party outside of our organization.

Security
We take precautions to protect your information. When you submit sensitive information via the website, your information is protected both online and offline.

Wherever we collect sensitive information (such as credit card data), that information is encrypted and transmitted to us in a secure way. You can verify this by looking for “https” at the beginning of the address of the web page.

Customer Service Contact Information

Phone: (868) 235-6800
Email: harts@hartscarnival.com

The Ultimate Hart Ltd. | 18 Rosalino Street, Woodbrook, Trinidad`;

export const theLostTribeTermsAndConditions = `Payment Policy

In order to successfully register and complete your costume booking, you are required in the first instance to issue to us a non-refundable deposit. All additional pieces/add-ons must be paid for in full at the time of registration. Please note that this deposit is required regardless of the price of the costume. You will be required to pay any balance on the selected costume by 31 st December 2023.

Minimum Deposit: TT2000 (male and female) (Masqueraders who add a backpack to their costume will be required to pay a deposit equivalent to 50% of the backpack cost by October 16 th ,2023)

Costumes must be paid in full prior to collection. Payments could be made via the following options:

1. At the Mas Camp (Cash & Linx) and online via Credit Card (Visa & MasterCard).
2. Cheques will not be accepted as a form of payment.

PLEASE NOTE:

YOUR REGISTRATION WILL ONLY BE COMPLETED AND VALID WHEN THE ENTIRE DEPOSIT IS MADE.

COSTUMES MUST BE PAID FOR IN FULL BY 31 ST DECEMBER 2023

Disclaimers

1. Masqueraders who fail to issue the full deposit by the due date will forfeit any and all monies initially issued as part-payment of the deposit. No exceptions allowed!
2. Costumes remain the property of the band until paid in FULL and collected.
3. If FULL payment is not paid by the stipulated deadline date, all monies will be forfeited.
4. Please note that ALL credit cards issued outside of Trinidad & Tobago are billed in US$. Important: For multi-currency credit cards, your credit card statement may reflect the billed amount converted at the banks' prevailing rate on that day. Also note that some banks/providers may charge an additional convenience fee.


Refunds/Returns Policy

Please note that carnival costumes are custom-made items therefore once a down-payment is made, it is non-refundable. Additionally, there will be:

• NO exchange or switching of sections.
• NO changing of measurements or sizes
• NO costume Transfers
• NO returns accepted

Delivery Policy

All costumes must be collected at the distribution centre by the owner. You must present a valid form of identification at the time of collection. Alternatively, you may authorize another person to collect on your behalf. The authorized person must present a valid form of identification.

The costume distribution schedule will be emailed to all masqueraders and posted to our social media platforms and website. Different sections will have different distribution dates. The final date of costume distribution is Carnival Thursday, after which all uncollected costumes are no longer available.

Consumer Data Privacy Statement

Information Collection, Use, and Sharing
We are the sole owners of the information collected on this site. We only have access to collect information that you voluntarily give us via email or other direct contact from you. We will not sell this information to anyone.

Your contact information will be used to communicate with you with regards to your costume and Carnival-related business. We may also contact you with special promotions and offers. We will not share your information with any third party outside of our organization.

Security
We take precautions to protect your information. When you submit sensitive information via the website, your information is protected both online and offline.

Wherever we collect sensitive information (such as credit card data), that information is encrypted and transmitted to us in a secure way. You can verify this by looking for “https” at the beginning of the address of the web page.

Customer Service Contact Information

Phone: (868) 235-6800
Email: info@losttribecarnival.com

Prelim Services Ltd. | 59 Alberto Street, Woodbrook, Trinidad`;

export const rogueTermsAndConditions = `Payment Policy

In order to successfully register and complete your costume booking, you are required in the first instance to issue to us a non-refundable deposit. All additional pieces/add-ons must be paid for in full at the time of registration. Please note that this deposit is required regardless of the price of the costume. You will be required to pay any balance on the selected costume by 31 st December 2023.

Minimum Deposit: TT$2000 (male and female)
All Add-ons must be paid for in full at the time of registration

Costume packages must be paid in full prior to collection. Payments could be made via the following options:

1. At the Mas Camp (Cash & Linx) and online via Credit Card (Visa & MasterCard).
2. Cheques will not be accepted as a form of payment.

PLEASE NOTE:

YOUR REGISTRATION WILL ONLY BE COMPLETED AND VALID WHEN THE ENTIRE DEPOSIT IS MADE.

COSTUMES MUST BE PAID FOR IN FULL BY 31 ST DECEMBER 2023

Disclaimers

1. Masqueraders who fail to issue the full deposit by the due date will forfeit any and all monies initially issued as part-payment of the deposit. No exceptions allowed!
2. Costumes remain the property of the band until paid for in FULL and collected.
3. If FULL payment is not paid by the stipulated deadline date, all monies will be forfeited.
4. Please note that ALL credit cards issued outside of Trinidad & Tobago are billed in US$. Important: For multi-currency credit cards, your credit card statement may reflect the billed amount converted at the banks' prevailing rate on that day. Also note that some banks/providers may charge an additional convenience fee.

Refunds/Returns Policy

Please note that carnival costumes are custom-made items therefore once a down-payment is made, it is non-refundable. Additionally, there will be:

• NO exchange or switching of sections.
• NO changing of measurements or sizes
• NO costume Transfers
• NO returns accepted

Delivery Policy

All costumes must be collected at the distribution centre by the owner. You must present a valid form of identification at the time of collection. Alternatively, you may authorize another person to collect on your behalf. The authorized person must present a valid form of identification.

The costume distribution schedule will be emailed to all masquerader and posted to our social media platforms and website. Different sections will have different distribution dates. The final date of costume distribution is Carnival Thursday, after which all uncollected costumes are no longer available.

Consumer Data Privacy Statement

Information Collection, Use, and Sharing
We are the sole owners of the information collected on this site. We only have access to collect information that you voluntarily give us via email or other direct contact from you. We will not sell this information to anyone.

Your contact information will be used to communicate with you with regards to your costume and Carnival-related business. We may also contact you with special promotions and offers. We will not share your information with any third party outside of our organization.

Security
We take precautions to protect your information. When you submit sensitive information via the website, your information is protected both online and offline.

Wherever we collect sensitive information (such as credit card data), that information is encrypted and transmitted to us in a secure way. You can verify this by looking for “https” at the beginning of the address of the web page.

Customer Service Contact Information

Phone: (868) 235-6800
Email: harts@hartscarnival.com

The Ultimate Hart Ltd. | 18 Rosalino Street, Woodbrook, Trinidad`;
